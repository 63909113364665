exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-cases-js": () => import("./../../../src/pages/cases.js" /* webpackChunkName: "component---src-pages-cases-js" */),
  "component---src-pages-dev-index-js": () => import("./../../../src/pages/dev/index.js" /* webpackChunkName: "component---src-pages-dev-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-prospects-js": () => import("./../../../src/pages/prospects.js" /* webpackChunkName: "component---src-pages-prospects-js" */),
  "component---src-pages-review-index-js": () => import("./../../../src/pages/review/index.js" /* webpackChunkName: "component---src-pages-review-index-js" */),
  "component---src-templates-case-study-js": () => import("./../../../src/templates/case-study.js" /* webpackChunkName: "component---src-templates-case-study-js" */)
}

